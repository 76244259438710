
/**
 * Função que adiciona o comportamento de execução com intervalos.
 *
 * @param {Function} callback função que está recebendo o comportamento
 * @param {number} timeBetween Tempo em milissegundos
 * @returns {Function}
 */
export function debounce (callback, timeBetween) {
  let first = true;
  let lastTimeStamp = Date.now();
  /**
   * @type {number}
   */
  let scheduledCallbackHandle = 0;

  return function debouncedVersion (...args) {
    // Armazena o momento da invocação
    const nowTimeStamp = Date.now();

    // Roda imediatamente se for a primeira vez
    if (first) {
      first = false;
      // eslint-disable-next-line
      callback(this, ...args);
    } else {
      const deltaTime = nowTimeStamp - lastTimeStamp;
      // Desinfilera o callback antigo
      if (scheduledCallbackHandle) {
        window.clearTimeout(scheduledCallbackHandle);
        scheduledCallbackHandle = 0;
      }
      // Roda imediatamente se tiver passado o tempo de espera
      if (deltaTime > timeBetween) {
        // eslint-disable-next-line
        callback(this, ...args);
      } else {
        // eslint-disable-next-line
        const scheduledCallback = () => callback(this, ...args);
        scheduledCallbackHandle = window.setTimeout(scheduledCallback, timeBetween);
      }

      // Atualiza lastTimeStamp
      lastTimeStamp = nowTimeStamp;
    }
  };
}
